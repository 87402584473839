
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { HubsObject } from '@/store/modules/assets/HubsModule';

  export default defineComponent({
    name: 'hub-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    methods: {
      usePlace(place) {
        if (place) {
          this.markers.pop();
          this.coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.center = this.coordinates;
          this.markers.push({
            id: this.markers.length + 1,
            position: this.coordinates,
          });
          this.hub.latitude = place.geometry.location.lat();
          this.hub.longitude = place.geometry.location.lng();
        }
      },
      updateCoordinates(location) {
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.hub.latitude = location.latLng.lat();
        this.hub.longitude = location.latLng.lng();
      },
      addMarker(location) {
        console.log(location);
        this.markers.pop();

        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.markers.push({
          id: this.markers.length + 1,
          position: this.coordinates,
        });
        this.hub.latitude = location.latLng.lat();
        this.hub.longitude = location.latLng.lng();
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('hubUpdating'), [
          translate('assets'),
        ]);
      });

      const goBack = () => {
        router.back();
      };

      const coordinates: any = { lat: '', lng: '' };

      const markers = [
        {
          id: 1,
          position: {
            lat: 33.2209264,
            lng: 43.714387,
          },
        },
      ];

      const { data } = await store.dispatch(Actions.GET_HUB, route.params.id);

      const hub = ref<HubsObject>(data.data);

      const center = { lat: 33.2209264, lng: 43.714387 };

      if (hub.value.latitude && hub.value.longitude) {
        center.lat = Number(hub.value.latitude);
        center.lng = Number(hub.value.longitude);
        markers[0].position.lat = Number(hub.value.latitude);
        markers[0].position.lng = Number(hub.value.longitude);
      }

      const titleAr = () => {
        return hub.value.name.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return hub.value.name.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return hub.value.name.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      const areaSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        latitude: Yup.string()
          .required(() => translate('LATITUDE_IS_REQUIRED_FIELD'))
          .label('Latitude'),

        longitude: Yup.string()
          .required(() => translate('LONGITUDE_IS_REQUIRED_FIELD'))
          .label('Longitude'),
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
      });
      const onSubmitCreate = async (values) => {
        values.name = [];
        values.name.push({ languageCode: 'en', title: values.titleEn });
        values.name.push({ languageCode: 'ar', title: values.titleAr });
        values.name.push({ languageCode: 'ku', title: values.titleKu });

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        delete values.titleEn;
        delete values.titleAr;
        delete values.titleKu;

        values.mobile = '964' + values.mobile;

        await store.dispatch(Actions.UPDATE_HUB, {
          data: values,
          id: hub.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HUB'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'hubs-listing' });
          });
        }
      };

      const hubMobile = computed(() => {
        const mobileString = String(hub.value.mobile);
        const last7Digits = mobileString.slice(-10);
        return String(last7Digits);
      });

      return {
        coordinates,
        center,
        markers,
        hubMobile,
        hub,
        titleAr,
        titleEn,
        titleKu,
        onSubmitCreate,
        selectedItem,
        submitButton,
        translate,
        areaSchema,
        goBack,
        can,
      };
    },
  });
